import React from 'react'
import Slider from '@mui/material/Slider'
import TextField from "@mui/material/TextField"
import { css } from "@emotion/react"

const cssSliderContainer = css`
  width: 100%;
  align-items: baseline;
  display: flex;
`

const cssTextField = css`
  margin-right: 10px;
`

const SliderWithText = (props) => {
  const { value, min, max, step, label } = props

  const handleSliderChange = (event, value) => {
    props.onChange(event, value)
  }

  const handleChange = event => {
    props.onChange(event, event.target.value)
  }

  return (
    <div css={cssSliderContainer}>
      <TextField
        label={label}
        css={cssTextField}
        InputProps={{classes: { input: { width: '4em'}}}}
        value={value}
        onChange={handleChange}
        margin="normal"
        type="number"
      />
      <Slider
        classes={{ container: { padding: '0px 0px'} }}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={handleSliderChange}
      />
    </div>
  )
}

export default SliderWithText
